import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Switch,
    TextField,
    Theme,
    Typography,
    Divider,
    withStyles,
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {retrieveProfile, selectProfile, updateProfile, updateContact,} from "../registrations/registrationsSlice";
import {selectToken} from "../authentication/authenticationSlice";
import Config from "../../config";
import {retrieveRegistrations, selectRegistration, selectRegistrations, updateRegistration,} from "../registrations/registrationsSlice";
import {Profile as ProfileType, ProfileFactory, ProfileJSON,} from "../../models/models";
import {Registration, RegistrationFactory, RegistrationJSON} from "../../models/models";
import {useSnackbar} from "notistack";
import {ProfileSwitch} from "../firstLoginFlow/FirstLoginFlowDialog";
import {retrieveEvent, selectEvent} from "../badge/eventSlice";
import {daysUntilEvent} from "../../utils/dateFunctions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
  })
);

export const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const profile = useSelector(selectProfile(Config.getInstance().getRegistrationID()));
  const registration = useSelector(selectRegistration(Config.getInstance().getRegistrationID()));
  const registrations = useSelector(selectRegistrations);
  const event = useSelector(selectEvent);

  const authToken = useSelector(selectToken);

  useEffect(() => {
    if (authToken) {
      dispatch(retrieveEvent(authToken, Config.getInstance().getEventID()));
      dispatch(retrieveProfile(authToken,Config.getInstance().getEventID(),Config.getInstance().getRegistrationID()));
      dispatch(retrieveRegistrations(authToken, Config.getInstance().getEventID()));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const profileFactory = new ProfileFactory();
  const registrationFactory = new RegistrationFactory();
  const [mutableProfile, setMutableProfile] = useState<ProfileJSON>(profileFactory.toJSON(profile));
  const [mutableRegistration, setMutableRegistration] = useState<RegistrationJSON>(registrationFactory.toJSON(registration));
  const [showDietaryNeeds, setShowDietaryNeeds] = useState<boolean>(false);

  useEffect(() => {
    if (profile) {
      setMutableProfile(profileFactory.toJSON(profile));
      if (profile.dietaryNeeds === ""){
        setShowDietaryNeeds(false);
      }
      else {
        setShowDietaryNeeds(true);
      }
    }
  }, [JSON.stringify(profileFactory.toJSON(profile))]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (registration) {
      setMutableRegistration(registrationFactory.toJSON(registration));
    }
  }, [JSON.stringify(registrationFactory.toJSON(registration))]);


//deprecated and replaced by save compelte information including the contact
  const saveProfile = (
    authToken: string,
    eventID: string,
    registrationID: string,
    profile: ProfileType
  ) => {
    dispatch(updateProfile(authToken, eventID, registrationID, profile));

    enqueueSnackbar("Profile has been updated.", {
      variant: "success",
    });
  };

  const saveProfileInformation = (
    authToken: string,
    eventID: string,
    registrationID: string,
    profile: ProfileType,
    registration: Registration,
  ) => {

    //check if agreed to terms and conditions
    if (!mutableProfile.mobile_phone_number){
      enqueueSnackbar("Please provide a mobile phone number.", {
          variant: "error",
      });
    }
    else if (profile.acceptLegalNotice && profile.allowDietaryNeedsSharing) {
        //find the index for which the registration shall be updated in all registrations array)
        const participant = registrations.filter(
                                   (registration) => registration.id === Config.getInstance().getRegistrationID())[0];
        const registrationIndex = registrations.indexOf(participant);

        let registrationsCopy = registrations;
        registrationsCopy[registrationIndex] = registration;

        dispatch(updateContact(authToken, eventID, registrationID, profile, registrationsCopy));

        enqueueSnackbar("Profile has been updated.", {
            variant: "success",
        });
    }
    else {
        enqueueSnackbar("Please accept the terms and conditions to save the profile details.", {
            variant: "error",
        });
    }

  };



  const differenceDays = daysUntilEvent(new Date(event.eventDate));

  return (
    <Box className={classes.container}>

      <Typography
        variant="h6"
        color="textPrimary"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        Profile
      </Typography>

      <Box style={{ height: 40 }} />
        <Typography
          color="textPrimary"
          align="left"
          style={{ fontWeight: "bold" }}
        >
          Allergies & Food restrictions
        </Typography>

        <Typography color="textSecondary" align="left">
          {differenceDays < 7 ?
              "Since we are already close to the event, please provide any allergies and food restrictions that are differing from the given ones below directly to your contact person"
            :
              "Please provide any allergies, food restrictions or other comments below"
            }
        </Typography>

        <Box className={classes.row}>
          <Typography
             color="textPrimary"
             align="left"
          >
            No
          </Typography>

          <Switch
             aria-label="show_dietary_needs"
             color="primary"
             disabled={differenceDays < 7}
             defaultChecked={showDietaryNeeds}
             checked={showDietaryNeeds}
             onChange={(_, value) => {
               setShowDietaryNeeds(value)
               if(!value){
                  setMutableProfile({
                      ...mutableProfile,
                      dietary_needs: "",
                  })
               }
             }
           }
          />
          <Typography
             color="textPrimary"
             align="left"
          >
            Yes
          </Typography>
        </Box>

        {showDietaryNeeds &&
        <>
          <Box style={{ marginBottom: 10,
                        marginTop: 0 }} />

          <TextField
            label="Food restrictions (max. 255)"
            multiline
            rows={4}
            disabled={differenceDays < 7 ? true : false}
            variant="filled"
            color="primary"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              maxLength: 255,
            }}
            value={mutableProfile.dietary_needs}
            onChange={(event) =>
              {
                setMutableProfile({
                    ...mutableProfile,
                    dietary_needs: event.target.value,
                })
              }
            }
        />
        </>
      }

        <Box style={{ marginBottom: 10,
                      marginTop: 10 }}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Other Information
            </Typography>
            <Typography color="textSecondary" align="left">
               Please provide some further personal details
            </Typography>
        </Box>
        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Mobile Phone Number"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          value={mutableProfile.mobile_phone_number}
          onChange={(event) =>
            setMutableProfile({
              ...mutableProfile,
              mobile_phone_number: event.target.value,
            })
          }
        />


        <Box style={{ marginBottom: 10,
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between" }}>
          <Box style={{ flex: 1 }}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Legal Notice
            </Typography>
            <Typography color="textSecondary" align="left">
               Please note that the above data will only be used for internal communication. Food restictions will be shared with the event venue.
              By checking this box, you agree to these terms.
            </Typography>
          </Box>
          <ProfileSwitch
            checked={mutableProfile?.allow_dietary_needs_sharing}
            onClick={() =>
              setMutableProfile({
                ...mutableProfile,
              allow_dietary_needs_sharing: !mutableProfile.allow_dietary_needs_sharing,
              })
            }
          />
        </Box>

        <Box style={{ height: 40 }} />
        <Typography
          color="textPrimary"
          align="left"
          style={{ fontWeight: "bold", marginBottom: 10 }}
        >
          Personal Information
        </Typography>
        <Typography color="textSecondary" align="left">
          Your personal contact information can be updated here
        </Typography>

        <Box style={{ height: 10 }} />


        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Name  (max. 35)"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
              maxLength: 35,
            }}
          value={mutableRegistration?.name}
          onChange={(event) =>
            setMutableRegistration({
              ...mutableRegistration,
              name: event.target.value,
            })
          }
        />


        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Function (max. 35)"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
              maxLength: 35,
            }}
          value={mutableRegistration?.title}
          onChange={(event) =>
            setMutableRegistration({
              ...mutableRegistration,
              title: event.target.value,
            })
          }
        />

        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="E-Mail Address"
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          value={mutableProfile.email}
          onChange={(event) =>
            setMutableProfile({
              ...mutableProfile,
              email: event.target.value,
            })
          }
        />

        <Divider style={{marginBottom: 10}}/>

        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Company Brand Name (without legal form such as GmbH, AG, SE, etc.)"
          variant="filled"
          disabled={true}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
              maxLength: 25,
            }}
          value={mutableRegistration?.company}
          onChange={(event) =>
            setMutableRegistration({
              ...mutableRegistration,
              company: event.target.value,
            })
          }
        />


        <TextField
          style={{ marginBottom: 10 }}
          size="small"
          label="Company Address"
          variant="filled"
          disabled={true}
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
              maxLength: 255,
            }}
          value={mutableProfile.address}
          onChange={(event) =>
            setMutableProfile({
              ...mutableProfile,
              address: event.target.value,
            })
          }
        />

        <TextField
          size="small"
          label="Company Phone (Switchboard)"
          variant="filled"
          disabled={true}
          InputProps={{
            disableUnderline: true,
          }}
          value={mutableProfile.phone_number}
          onChange={(event) =>
            setMutableProfile({
              ...mutableProfile,
              phone_number: event.target.value,
            })
          }
        />



        <Box style={{ marginBottom: 10,
                      marginTop: 20 }}>
            <Typography
              color="textPrimary"
              align="left"
              style={{ fontWeight: "bold" }}
            >
              Legal Notice
            </Typography>
            <Typography color="textSecondary" align="left">
                Due to multiple requests, the above data will only be shared with the participants who are registered for the event. In order to carry out the event in the desired quality, we need your consent to be able to participate.
            </Typography>

        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 10,
            marginTop: 10
          }}
        >
          <Box style={{ flex: 1 }}>
            <Typography color="textPrimary" align="left">
                Agreement
            </Typography>
            <Typography color="textSecondary" align="left">
              By checking this box, you agree to the above legal notice.
            </Typography>
          </Box>

          <ProfileSwitch
            checked={mutableProfile?.accept_legal_notice}
            onClick={() =>
              setMutableProfile({
                ...mutableProfile,
                accept_legal_notice: !mutableProfile.accept_legal_notice,
              })
            }
          />
        </Box>


      <Box style={{ height: 20 }} />
      <Button
        disabled={
        (
          JSON.stringify(registrationFactory.toJSON(registration)) ===
          JSON.stringify(mutableRegistration)
          &&
          JSON.stringify(profileFactory.toJSON(profile)) ===
          JSON.stringify(mutableProfile)
        )
        }
        color="primary"
        variant="contained"
              onClick={() =>
                authToken &&
                saveProfileInformation(
                  authToken,
                  Config.getInstance().getEventID(),
                  Config.getInstance().getRegistrationID(),
                  profileFactory.fromJSON(mutableProfile),
                  registrationFactory.fromJSON(mutableRegistration),
                )}
      >
        Save
      </Button>
    </Box>
  );
};
